import banner from 'assets/images/banner.png'
import BannerCardPage from 'components/layout/BannerCardPage'
import { NextSeo } from 'next-seo'
import { config } from 'data/config'

const Custom404 = () => {
  return (
    <>
      <NextSeo
        title="404 | Page not found"
        openGraph={{
          type: 'website',
          url: `${config.whitelabelURL}/404`,
          images: [
            {
              url: `${config.whitelabelURL}${banner?.src}`,
              width: 922,
              height: 700,
            },
          ],
        }}
      />

      <BannerCardPage
        bannerImage={banner}
        altText="404 Error"
        subtitle="404 Error"
        heading="We can't locate the page you're looking for"
        button={{
          href: '/',
          text: 'Back to homepage',
        }}
      >
        <>
          <p>It looks like the page you are looking for no longer exists.</p>
          <p>Not to worry! Head over to our homepage and get in touch with us directly.</p>
        </>
      </BannerCardPage>
    </>
  )
}

export default Custom404
